'use client'

import { ErrorHandler } from 'ui/components/ErrorHandler'
import { useSession } from 'auth/clerk'
import { useErrorReporting } from 'appsignal/client'
import Logo from '@/components/Logo'

const Error = ({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) => {
  // eslint-disable-next-line -- allow console log
  console.error('error handler', error)

  const session = useSession()

  useErrorReporting(error, session.session?.user.id || '')

  return (
    <ErrorHandler error={error} reset={reset}>
      <Logo />
    </ErrorHandler>
  )
}

export default Error
